@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap');

.navbar-brand {
  color: #d4cece;
  font-weight: bold;
  font-size: 1.5rem; /* Larger text for the brand */
}

.nav-link {
  color: #9fa9b3; /* Slightly lighter text for links */
  margin-right: 1rem; /* Space between links */
  transition: color 0.3s ease-in-out; /* Smooth transition for hover effect */
}

/* Hover effect for links */
.nav-link:hover {
  color: #ffffff; /* Change color on hover */
}

/* Adjustments for mobile screens */
@media (max-width: 992px) {
  .navbar-collapse {
      margin-top: 1rem; /* Add space above the collapsed menu */
  }

  .nav-link {
      display: block; /* Stack the links vertically */
      margin: 0.5rem 0; /* Add vertical space between links */
  }
}
.navbar {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700; 
  background-color: rgba(15, 12, 41, 0.85); /* Dark background with opacity */
  backdrop-filter: blur(10px); /* Blur effect for see-through background */
  border-bottom: 2px solid #d4dcdc00; /* Accent border color */
}
.navbar-collapse {
  margin-top: 0; /* Remove extra space above the collapsed menu */
}