.blogcontainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
  }
  
  .blogcard {
    width: 100%;
    max-width: 600px; /* Adjust based on your design preference */
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .blogcard a {
    text-decoration: none;
    color: inherit; /* Ensures the text isn't the default anchor tag blue */
  }
  
  .blogcard__header {
    width: 100%;
    display: block;
    overflow: hidden; /* Ensures the image doesn't overflow the card's border-radius */
  }
  
  .blogcard__image {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease-in-out;
  }
  
  .blogcard__body {
    padding: 1rem;
    background-color: #fff;
    border-top: 1px solid #eee; /* Adds a subtle division between image and text */
  }
  
  .blogcard__body h4 {
    font-size: 1.25rem; /* Adjust as needed */
    margin: 0;
    padding: 0;
    line-height: 1.5;
  }
  
  .blogcard:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
  }
  
  .blogcard:hover .blogcard__image {
    transform: scale(1.05); /* Slight zoom effect on hover */
  }
  