.card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh; /* Adjust height as necessary */
    background-color: #0d1117; /* Or any other background color */
    font-family: 'Arial', sans-serif; /* Use your desired font-family */
    border-radius: 30px;
  }
  
  .card {
    background-color: white;
    border-radius: 20px;
    padding: 2rem;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.25);
    width: 450px; /* Adjust width as needed */
    height: 550px;
    position: relative;
    overflow: hidden;

  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out; /* Smooth transition for height changes */
  }
  
  .card-title {
    font-size: 2rem;
    color: #333;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .card-body {
    margin-bottom: 2rem;
    padding: 20px;
    transition: all 0.3s ease-in-out; /* Smooth transition for height changes */
  }
  
  .card-buttons {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  .card-button {
    padding: 0.5rem 1.5rem;
    border: none;
    border-radius: 20px;
    font-size: 1rem;
    font-weight: bold;
    margin: 0 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .card-button.primary {
    background-color: #2979ff;
    color: white;
  }
  
  .card-button.secondary {
    background-color: #eff3f6;
    color: #333;
  }
  
  .card-button:hover {
    opacity: 0.8;
  }
  
  .card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9rem;
    color: #333;
    padding: 0.5rem 1rem;
    position: absolute;
    bottom: 0;
    width: calc(100% - 4rem);
    background-color: #f5f5f5;
    border-radius: 0 0 20px 20px;
  }
  
  .footer-icons {
    background-color: #0d1117;
  }
  .store{
    background-color: #0d1117;
    height: 250px;
  }
 .cur{
  margin-left: 40px;
 }
 .hand2{
  padding-bottom: 35px;
 }

 .move-right {
  padding-left: 40px;
}


.card-details {
  display: none; /* Initially hidden */
  transition: all 0.3s ease-in-out; /* Smooth transition for visibility */
}

/* When details are shown, apply these styles */
.card-details-visible {
  display: block; /* Shown */
  padding: 10px 0; /* Add some padding at the top and bottom */
}

.move-down{
  padding-bottom: 40px;
}
.card-expanded {
  height: 800px;
}