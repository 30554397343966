.box {
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* This will create as many columns as can fit in the space, with each column being at least 150px wide */
justify-content: center;
    gap: 20px;
    height: 800px;
    width: 70%;
    padding: 20px;
    background: linear-gradient(to right, #1c1c1e00, #2c2c2e00);
    border-radius: 30px;
  }
  
  .skillcard {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 300px;
    background-color: #000006; /* Card background */
    color: #fff; /* Text color */
    border-radius: 15px; /* Rounded corners */
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Card shadow */
  }
  .skillcard:hover{
    

  }
  
  .skillcard-content {
    display: flex;
    flex-direction: column;
  }
  
  .skillcard-content h2 {
    margin: 0;
    font-size: 1.5em;
  }
  
  .skillcard-content p {
    margin-top: 10px;
    font-size: 1em;
  }
  .card-image {
    width: 100%; /* Make the image take up the full width of the card */
    height: auto; /* Maintain aspect ratio */
    border-top-left-radius: 15px; /* Rounded corners at the top */
    border-top-right-radius: 15px;
  }