.icon-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px; /* Size of the circle */
    height: 70px; /* Size of the circle */
    background-color: rgb(244, 224, 247); /* Ensure this is set */
    border-radius: 50%;
    border: none; /* Ensure there's no border unless you want one */

  }
.chat-button {
  background-color: transparent; /* Set the background to transparent */
  border: none; /* Ensure there's no border */

    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    font-size: 43px;
  }
  
 /* Chat Box Styles */
/* Chat Box Styles */
.chat-box {
  width: 350px; /* Set according to your design preference */
  height: 500px; /* Set according to your design preference */
  display: flex;
  bottom: 20px; 
  right: 20px;
  flex-direction: column;
  background: #000; /* Dark background */
  border-radius: 25px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 1000; /* Ensure it floats above other content */
  font-family: 'Arial', sans-serif; /* Choose your font */
  position: fixed; /* To position close button and header */
}

.chat-header {
  background: #373737; /* Dark header background */
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-title {
  font-size: 18px;
}

.close-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 24px;
}

.chat-body {
  flex-grow: 1;
  overflow-y: auto;
  background: #1E1E1E; /* Dark body background */
  padding: 10px;
}

.messages-container {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between messages */
  background-color: rgba(240, 248, 255, 0);
  border-radius: 25px;
}

.message {
  padding: 10px 20px;
  border-radius: 18px;
  color: white; /* Text color */
  line-height: 1.5; /* Adjust line height for better readability */
  max-width: 80%; /* Message max width */
}

.message.user {
  background: #4A4B57; /* User message background */
  align-self: flex-end;
}
.message.bot {
  background-color: gray; /* Different color for bot responses */
  text-align: left;
}
.message.assistant {
  background: #5C5C5C; /* Assistant message background */
  align-self: flex-start;
}

.message-box {
  display: flex;
  align-items: center;
  padding: 10px;
  background: #373737; /* Match header background */
  border-top: 1px solid #4A4B57; /* Add top border for separation */
}

.message-input {
  flex-grow: 1;
  padding: 10px;
  margin-right: 10px;
  background: #000; /* Input background */
  color: white; /* Input text color */
  border: 1px solid #4A4B57; /* Add some border */
  border-radius: 15px; /* Rounded corners for input */
  outline: none; /* Remove outline */
}

.message-submit {
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.send-icon {
  width: 24px; /* Icon size */
  height: 24px; /* Icon size */
  fill: #6B6C7B;
}

/* Hide scrollbar for a cleaner look */
.chat-body::-webkit-scrollbar {
  display: none;
}

.chat-body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.gptres{
  background-color: aquamarine;
}
.loading-dots{
 
  margin: 32px;
  span {
    width: 10px;
    height: 10px;
    background-color: #f7f7f7;
    display: inline-block;
    margin: 1px;
    border-radius: 50%;
    &:nth-child(1) {
      animation: bounce 1s infinite;
    }
    &:nth-child(2) {
      animation: bounce 1s infinite .2s;
    }
    &:nth-child(3) {
      animation: bounce 1s infinite .4s;
    }
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0px);
  }
}