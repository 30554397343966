body {
  height: 100vh;
  margin: 0;
  padding: 0;
  background: linear-gradient(to right, #0F0C29, #302B63);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: white; /* Ensures text stands out against the dark background */
}
/* * {
  box-sizing: border-box;
  outline: 1px solid red; /* Temporarily add an outline to all elements to see which one might be overflowing */
/* } */ 

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-on-scroll {
  animation: 1s ease-out 0s 1 slideInFromLeft;
}


.secondlayer, .thirdlayer, .fourthlayer, .fifthlayer {
  padding: 50px 0; /* Adjust the padding as necessary */
  margin: 20px 0; /* Adjust the margin as necessary */
}
.secondlayer, .thirdlayer, .fourthlayer, .fifthlayer {
  min-height: 100vh; /* Adjust the min-height as necessary */
  margin-top: 100px;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0; /* this ensures the header stretches fully from left to right */
  z-index: 1100;
}

.App-link {
  color: #878787;
}

.secondlayer {
  display: flex; /* Enables Flexbox for this container */
  justify-content: space-around; /* Distributes space around the items */
  align-items: center; /* Aligns items vertically in the center */
  margin-top: -100px;
  background: linear-gradient(145deg, #2e2585, #232223, #29296b);

}



.htmllogo{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  pointer-events: none;
  display: flex;
  align-content: center;
  object-fit: contain;
}





.fourthlayer{
  display: flex; /* Enables Flexbox for this container */
  justify-content: space-around; /* Distributes space around the items */
  align-items: center; /* Aligns items vertically in the center */
  margin-top: -200px;
  flex-direction: row;

background-color: #d4cfc5;


}
.fourthlayer .container {
  display: flex;
  justify-content: space-around; /* This will space your cards evenly */
  flex-wrap: wrap; /* This will allow cards to wrap onto the next line on smaller screens */
}
