footer {
    background-color: #333; /* Replace with the actual color from the image */
    color: #fff;
    padding: 20px;
    font-family: sans-serif; /* Replace with the actual font used */
  
  }
  
  .footer-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .footer-section h4 {
    color: #aaa; /* Light grey color for headings */
    margin-bottom: 10px;
  }
  
  .footer-section a {
    color: #fff;
    text-decoration: none;
    display: block; /* Stack links vertically */
    margin-bottom: 5px;
  }
  
  .footer-meta,
  .footer-nav {
    text-align: center;
    margin-bottom: 10px;
  }
  
  .footer-nav button {
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
    padding: 10px 20px;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .footer-nav button:hover {
    background-color: #fff;
    color: #333; /* Dark color for text when button is hovered */
  }
  
  /* Add responsiveness */
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-section {
      margin-bottom: 20px;
    }
  
    .footer-nav {
      display: flex;
      flex-direction: column;
    }
  }
  .social-logo {
    width: 20px; /* Example size, adjust as needed */
    height: 20px; /* Keep the same as width for a circle */
    border-radius: 50%; /* This makes the image circular */
    margin-right: 10px; /* Adjust the spacing between logo and text as needed */
  }
  .footer-section a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #fff;
    margin-bottom: 5px;
  }