@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
.firstlayer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5% 10%;
    background: linear-gradient(135deg, #702c7c 0%, #16213e 60%, #0a2240 100%);
    color: white;
    width: 100%;
    min-height: 100vh;
    position: relative;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 8px;
}

.intro, .introinfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 45%; /* Adjusted max width */
    margin: 2%;
    background-color: transparent; /* Remove any background for a cleaner look */
    padding: 20px;
}

.intro h1 {
    font-size: 4rem; /* Adjusted for responsiveness */
    color: #ffffff;
    text-shadow: 2px 2px 4px #000000;
    font-family: 'Montserrat', sans-serif;
}

.introinfo p {
    font-size: 1.25rem;
    line-height: 1.5;
    color: #cccccc;
    text-shadow: 1px 1px 2px #000000;
}

.download-cv-button-dark {
    padding: 15px 30px;
    background-color: #252525;
    color: #afb5b5; /* Futuristic accent color */
    border: 1px solid #000000; /* Border color */
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    cursor: pointer;
}

.download-cv-button-dark:hover {
    background-color: #0d1e1c;
    color: #f3f3f3;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

/* Adjustments for smaller screens (<1024px) */
@media (max-width: 1024px) {
    .intro, .introinfo {
        max-width: 50%; /* Increase max width for medium-sized screens */
        margin: 0 1%; /* Adjust margins */
    }
}

/* Adjustments for mobile screens (<768px) */
@media (max-width: 768px) {
    .firstlayer {
        flex-direction: column; /* Stack vertically on small screens */
        align-items: center; /* Align items to the start */
        padding: 20px; /* Add padding */
    }
.intro{
    margin-top: 200px;
}
    .intro, .introinfo {
        max-width: 100%; /* Allow sections to take full width */
        align-items: center; /* Center-align items */
        margin-bottom: 20px; /* Add bottom margin for spacing */
    }

    .intro h1, .introinfo p {
        font-size:  1.5rem; /* Adjust font size for mobile readability */
        text-align: center; /* Center-align text */
    }
}
