.thirdlayer{
  background-position: center;
  background-size: cover;
  height: 110vh;
margin-top: -70px;
}
.cardLink {
    text-decoration: none;
    color: inherit;
  }
  
  .cardContainer {
    width: 300px;
    height: 400px;
    background: linear-gradient(to right, #0a3239, #1c2534);
    border-radius: 0.5rem;
   
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
                0 4px 6px -2px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .cardContainer:hover {
    transform: translateY(-0.25rem) scale(1.05);
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.2),
                0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  
  .cardContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
  }
  
  .cardLogo {
    width: 4rem;
    height: 4rem;
    margin-bottom: 1rem;
  }
  
  .cardTitle {
    font-size: 1.25rem;
    font-weight: bold;
    color: white;
    margin-bottom: 0.5rem;
  }
  
  .cardDescription {
    color: rgba(255, 255, 255, 0.9);
    font-size: 0.875rem;
    text-align: center;
  }
  