.card2 {
    position: relative;
    width: 300px;
    height: 400px;
    background-color: #2b2a2a67;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    perspective: 1000px;
    box-shadow: 0 0 0 5px #ffffff00;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  
  .card2 svg {
    width: 48px;
    fill: #333;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  
  .card2:hover {
    transform: scale(1.08);
    box-shadow: 0 8px 16px rgba(255, 255, 255, 0.2);
  }
  
  .card__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #131313;
    transform: rotateX(-90deg);
    transform-origin: bottom;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  
  .card2:hover .card__content {
    transform: rotateX(0deg);
  }
  
  .card__title {
    margin: 0;
    font-size: 24px;
    color: #c9bebe;
    font-weight: 700;
  }
  
  .card2:hover svg {
    scale: 0;
  }
  
  .card__description {
    margin: 1px 0 0;
    font-size: 14px;
    color: #959595;
    line-height: 1.4;
  }
  .card-button-primary {
    border-radius: .29rem;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    padding-left: 25px;
    padding-right: 25px;
    color: #8c8989;
    height: 50px;
    font-size: 2.9rem;
    line-height: 14px;
    letter-spacing: 1.2px;
    transition: .2s .1s;
    background-image: linear-gradient(90deg,#1c1c1c,#000000);
    border: 0 solid;
    overflow: hidden;
    width: 300px;
  }
  .card-button-secondary{
    border-radius: .29rem;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 300;
    padding-left: 25px;
    padding-right: 25px;
    color: #8c8989;
    height: 50px;
    font-size: 2.9rem;
    line-height: 14px;
    letter-spacing: 1.2px;
    transition: .2s .1s;
    background-image: linear-gradient(90deg,#1c1c1c,#000000);
    border: 0 solid;
    overflow: hidden;
    width: 300px;
  }
  button:hover {
    cursor: pointer;
    transition: all .3s ease-in;
   
  }